import './lib/login.js';
import './lib/changeFacilities';
import './lib/userAccountList';



/**
 * フォーム
 */

const rules = {
  category: {
    required: '選択してください'
  },
  purpose: {
    required: '選択してください'
  },
  request: {
    required: '選択してください'
  },
  contents: {
    required: '入力してください'
  }
};



const group = {
  uploadfile: 'uploadfile_a',
};



vivaForm({
  formname: 'inquiryForm',
  rules: rules,
  group: group
});



// メールアカウントリストのプレースホルダー
(function(){
  const mailAccountList = document.getElementById('mailAccountList');
  if( !mailAccountList )return;
  const placeholder = `メールアドレスを1行に1件ずつご記入ください。

  入力例）
  sample@kotobuki-ent.co.jp
  kotobuki@kotobuki-ent.co.jp
  :
  `;

  mailAccountList.placeholder = placeholder;
}());
